
window.THREE = require('./node_modules/three-full/sources/Three.js');
const mathObj = require('./node_modules/three-full/sources/math/Math.js');
require('./node_modules/three-full/sources/controls/OrbitControls');
require('./node_modules/three-full/sources/loaders/GLTFLoader');

window.THREE.Math = mathObj._Math;

// require("./js/three.js");
// require("./js/THREE/libs/controls/OrbitControls.js");
// require("./js/THREE/libs/loaders/GLTFLoader.js");